import { DataRow } from "../../../services/corpCompanyConfiguration";
import { IColumnSortStates, ISortableColumns, DataRowKey } from "../typings/table";

export function createSortableColumns(): ISortableColumns {
  return {
    active: {
      state: "default",
      iconName: "sorter",
      activeName: "",
    },
    default: {
      state: "default",
      iconName: "sorter",
    },
  };
}

export function defineColumnSortStates(
  sortColumnDescending: (row1: DataRow, row2: DataRow, columnKey: DataRowKey) => number,
  sortColumnAscending: (
    row1: DataRow,
    row2: DataRow,

    columnKey: DataRowKey
  ) => number
): IColumnSortStates {
  return {
    default: {
      state: "default",
      next: "descending",
      sortingFunction: null,
      iconName: "sorter",
    },
    descending: {
      state: "descending",
      next: "ascending",
      sortingFunction: sortColumnDescending,
      iconName: "arrow-down",
    },
    ascending: {
      state: "ascending",
      next: "default",
      sortingFunction: sortColumnAscending,
      iconName: "arrow-up",
    },
  };
}

export function sortColumnDescending(row1: DataRow, row2: DataRow, columnKey: DataRowKey): number {
  const row1ColumnValue = getColumnValue(row1, columnKey);
  const row2ColumnValue = getColumnValue(row2, columnKey);

  return getStringSortNumber(row2ColumnValue, row1ColumnValue);
}

export function sortColumnAscending(row1: DataRow, row2: DataRow, columnKey: DataRowKey): number {
  const row1Value = getColumnValue(row1, columnKey);
  const row2Value = getColumnValue(row2, columnKey);

  return getStringSortNumber(row1Value, row2Value);
}

function getColumnValue(row: DataRow, columnKey: DataRowKey): string | boolean {
  return row[columnKey];
}

function getStringSortNumber(val1: string | boolean, val2: string | boolean): number {
  const intVal1 = typeof val1 === "boolean" ? String(val1) : val1;
  const intVal2 = typeof val2 === "boolean" ? String(val2) : val2;

  const collator = new Intl.Collator("sv", { numeric: true });

  return collator.compare(intVal1, intVal2);
}
