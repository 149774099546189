<template>
  <telia-p t-id="telia-at-work-drawer-description" variant="paragraph-200" disableHyphenation>
    {{ getDescription }}
  </telia-p>
  <b2x-loading-button
    v-if="orgCount > 0 && !successfullyUpdatedOrganizations"
    variant="primary"
    :is-loading="submitting"
    :full-width="fullWidthButton"
    @click="submitDeactivateOrgs"
    >{{
      t("mybusiness.company_settings.telia_at_work.drawer.deactivate.button")
    }}</b2x-loading-button
  >

  <div class="notification">
    <telia-notification
      v-if="showErrorNotification"
      status="error"
      :button-text="t('mybusiness.close')"
      heading-tag="h3"
      @vocaClose="() => (showErrorNotification = false)"
    >
      <span slot="heading">{{
        t("mybusiness.company_settings.telia_at_work.error_notification.header")
      }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{
          t("mybusiness.company_settings.telia_at_work.deactivate.error_notification.body")
        }}</telia-p>
      </telia-text-spacing>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { logError } from "@telia/b2x-logging";

import { translateMixin } from "../../locale";

import { DataResponse, postData } from "../../services/corpCompanyConfiguration";

import { trackAnalytics } from "../../utils/trackGA4";

const props = defineProps<{
  orgs: DataResponse;
  fullWidthButton: boolean;
}>();

const emit = defineEmits<{ (e: "successfullyUpdatedOrganizations"): void }>();

const t = translateMixin.methods.t;
const submitting = ref(false);
const showErrorNotification = ref(false);
const successfullyUpdatedOrganizations = ref(false);

onMounted(() => {
  successfullyUpdatedOrganizations.value = false;
  showErrorNotification.value = false;
});

const getDescription = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return t(
      "mybusiness.company_settings.telia_at_work.drawer.deactivate.succesful_deactivation_description"
    );
  }

  return orgCount.value === 0
    ? t("mybusiness.company_settings.telia_at_work.drawer.deactivate.no_organizations_selected")
    : t("mybusiness.company_settings.telia_at_work.drawer.deactivate.description", {
        orgCount: orgCount.value,
      });
});

const orgCount = computed(() => {
  return props.orgs.length;
});

const submitDeactivateOrgs = async () => {
  try {
    submitting.value = true;
    const tscids = props.orgs.map((org) => org.tscid);
    const body = {
      tscids,
      allowed: false,
    };

    await postData(body);

    successfullyUpdatedOrganizations.value = true;
    trackAnalytics("company_settings_telia_at_work_complete", {
      access_action: "deactivate",
    });
    emit("successfullyUpdatedOrganizations");
  } catch (error) {
    showErrorNotification.value = true;
    trackAnalytics("company_settings_telia_at_work_fail", {
      access_action: "deactivate",
    });
    logError("b2b-company-settings", "Failed to call ????");
  } finally {
    submitting.value = false;
  }
};
</script>

<style lang="scss" scoped>
telia-p {
  margin-bottom: var(--purpur-spacing-400);
}

.notification {
  margin-top: var(--purpur-spacing-400);
}
</style>
