<template>
  <div>
    <telia-text-input
      t-id="invoice-email-filter"
      type="search"
      :label="t('COMPANY_SETTINGS.INVOICE_EMAIL.EMAIL_FILTER_LABEL')"
      @input="(event) => filterEmails(event)"
    />
  </div>
</template>

<script>
import { translateMixin } from "../../../src/locale";

export default {
  name: "emailFilter",
  inject: ["invoiceEmailStateService"],
  mixins: [translateMixin],
  methods: {
    filterEmails(event) {
      this.invoiceEmailStateService.filterEmails(event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
div {
  min-width: 30rem;
}
</style>
