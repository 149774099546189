import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

enum Feature {
  ACTIVATE_EDI = "activate-edi",
}

const toggles: Record<Feature, boolean> = {
  [Feature.ACTIVATE_EDI]: false,
};

export const isActivateEdiFeatureEnabled = (): boolean => isEnabled(Feature.ACTIVATE_EDI);

function isEnabled(toggleName: Feature): boolean {
  return featureToggleIsEnabled(toggleName, toggles);
}
