<template>
  <telia-button
    t-id="telia-at-work-download-pdf"
    @click="handleButtonClick"
    variant="text"
    type="button"
  >
    <telia-icon name="download" slot="left" size="sm"></telia-icon>
    {{ buttonText }}</telia-button
  >
</template>

<script setup lang="ts">
import { translateMixin } from "../../locale";

import { trackAnalytics } from "../../utils/trackGA4";

const t = translateMixin.methods.t;

const buttonText = t("mybusiness.company_settings.telia_at_work.links.pdf");

const handleButtonClick = () => {
  trackAnalytics("company_settings_telia_at_work_progress", {
    click_action: "download",
    click_type: "button",
    click_text: buttonText,
  });
};
</script>

<style lang="scss"></style>
