<template>
  <section
    t-id="telia-at-work-section"
    class="telia-at-work-section"
    :aria-label="t('mybusiness.company_settings.telia_at_work.heading')"
  >
    <div class="telia-at-work-content">
      <telia-p t-id="telia-at-work-description" variant="paragraph-200" v-html="description" />
      <DownloadPdfButton />
    </div>

    <template v-if="!errorFetching">
      <b2x-controlled-table
        :is-select-row-checkbox="
          JSON.stringify({
            headerEnabled: true,
            rowsEnabled: true,
            headerText: t('mybusiness.company_settings.telia_at_work.table.headers.checkbox'),
          })
        "
        :columns="JSON.stringify(tableHeaders)"
        :data="JSON.stringify(mappedTableData)"
        :table-rows-count="tableRowsCount"
        :table-rows-limit="mappedTableData.length"
        :is-loading="loading"
        :is-all-checked="areAllFilteredRowsSelected"
        :is-checkbox-indeterminate="isCheckboxIndeterminate"
        :message="getTableMessage"
        :actions="tableActions"
        show-filters
        show-filter-row
        @columnClicked="headerClicked"
        @filterColumnInput="onFilterColumnInput"
        @rowCheckboxClicked="onRowCheckboxClicked"
        @actionButtonClickedEvent="onActionButtonClickedEvent"
      />
      <b2x-paginator
        v-if="!loading"
        @paginationChange="onPaginationChange"
        :page-sizes="JSON.stringify([10, 25, 50, 100])"
        :list-length="tableRowsCount"
        default-page-size="10"
      />
      <div v-else t-id="b2x-paginator-skeletons" class="paginator-skeletons">
        <telia-skeleton />
        <telia-skeleton />
      </div>
    </template>

    <telia-notification
      v-if="errorFetching && showErrorNotification"
      status="error"
      :button-text="t('mybusiness.close')"
      heading-tag="h3"
      @vocaClose="() => (showErrorNotification = false)"
    >
      <span slot="heading">{{
        t("mybusiness.company_settings.telia_at_work.error_notification.header")
      }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{
          t("mybusiness.company_settings.telia_at_work.error_notification.body")
        }}</telia-p>
      </telia-text-spacing>
    </telia-notification>

    <TeliaAtWorkDrawer
      :isOpen="isDrawerOpen"
      :action="drawerAction"
      :selectedOrgs="selectedOrgs"
      @drawer-close="closeDrawer"
    />
  </section>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from "vue";

import { logError } from "@telia/b2x-logging";

import { createTableHeaders } from "./helpers/table";
import {
  createSortableColumns,
  defineColumnSortStates,
  sortColumnAscending,
  sortColumnDescending,
} from "./helpers/sorting";

import { translateMixin } from "../../locale";

import TeliaAtWorkDrawer from "./telia-at-work-drawer.vue";
import DownloadPdfButton from "./download-pdf-button.vue";

import { DataResponse, DataRow, getData } from "../../services/corpCompanyConfiguration";

import { ISortableColumns, ITableHeader, TableRowData } from "./typings/table";

import { trackAnalytics, trackLinkInteraction } from "../../utils/trackGA4";

const t = translateMixin.methods.t;

const description = t("mybusiness.company_settings.telia_at_work.description", {
  link: "<telia-link id='telia-at-work-link' href='/foretag/telia-at-work'>Telia at Work</telia-link>",
});

const trackAnalyticsTeliaAtWorkLink = () => {
  trackLinkInteraction("click", "Telia at Work", "text_link");
};

const data = ref<DataResponse>([]);

onBeforeMount(() => {
  fetchData();
});

onMounted(() => {
  document
    .getElementById("telia-at-work-link")
    ?.addEventListener("click", trackAnalyticsTeliaAtWorkLink);
});

onUnmounted(() => {
  document
    .getElementById("telia-at-work-link")
    ?.removeEventListener("click", trackAnalyticsTeliaAtWorkLink);
});

const columnSortStates = defineColumnSortStates(sortColumnDescending, sortColumnAscending);
const columnTranslationToAttribute = ref<Record<string, Exclude<keyof DataRow, "tscid">>>({
  [t("mybusiness.company_settings.telia_at_work.table.headers.company_name")]: "organizationName",
  [t("mybusiness.company_settings.telia_at_work.table.headers.organization_number")]:
    "organizationNumber",
  [t("mybusiness.company_settings.telia_at_work.table.headers.status")]: "active",
});
const filterOnColumns = ref<
  {
    column: Exclude<keyof DataRow, "tscid">;
    value: string;
  }[]
>([]);

type PageSizes = 10 | 25 | 50 | 100;
const pagination = ref<{
  page: number;
  pageSize: PageSizes;
}>({
  page: 1,
  pageSize: 10,
});
const selectedOrgs = ref<DataResponse>([]);
const sortableColumns = ref<ISortableColumns>(createSortableColumns());
const tableData = ref<DataResponse>([]);
const tableHeaders = ref<ITableHeader[]>(createTableHeaders(t));
const tableRowsCount = ref<number>(0);
const isDrawerOpen = ref(false);
const drawerAction = ref<"activate" | "deactivate">("activate");
const loading = ref(true);
const errorFetching = ref(false);
const showErrorNotification = ref(false);

const tableActions = computed(() => {
  return [
    {
      text: t("mybusiness.company_settings.telia_at_work.table.buttons.activate", {
        count: selectedOrgs.value.length,
        total: data.value.length,
      }),
      variant: "primary",
      value: "activate",
    },
    {
      text: t("mybusiness.company_settings.telia_at_work.table.buttons.deactivate", {
        count: selectedOrgs.value.length,
        total: data.value.length,
      }),
      variant: "secondary",
      value: "deactivate",
    },
  ];
});

const areAllFilteredRowsSelected = computed(() => {
  return (
    filteredData.value.length > 0 &&
    filteredData.value.every((row) => selectedOrgs.value.includes(row))
  );
});

const isCheckboxIndeterminate = computed(() => {
  return !areAllFilteredRowsSelected.value && selectedOrgs.value.length > 0;
});

const generateTableData = (switchSortingState = true, firstClickOnNewHeader = false) => {
  let tempData = [...filteredData.value];

  tableRowsCount.value = tempData.length;

  if (sortableColumns.value.active.activeName !== "") {
    tempData = sortData(switchSortingState, firstClickOnNewHeader, tempData);
  }

  tableData.value = paginateData(tempData);
};

const mappedTableData = computed<TableRowData[]>(() => {
  return tableData.value.map((org) => {
    const isChecked = selectedOrgs.value.includes(org);
    return [
      isChecked,
      org.organizationName,
      org.organizationNumber,
      {
        content: org.active
          ? t("mybusiness.company_settings.telia_at_work.status.activated")
          : t("mybusiness.company_settings.telia_at_work.status.deactivated"),
        variant: org.active ? "positive" : "neutral",
      },
    ];
  });
});

const filteredDataIsEmpty = computed(() => {
  return filteredData.value.length === 0;
});

const getTableMessage = computed(() => {
  return filteredDataIsEmpty.value
    ? t("mybusiness.company_settings.telia_at_work.table.empty_message")
    : "";
});

const fetchData = async () => {
  try {
    data.value = await getData();

    if (data.value.length === 0) {
      trackEmptyTable();
    }

    loading.value = false;
    generateTableData();
  } catch (error) {
    errorFetching.value = true;
    showErrorNotification.value = true;
    logError("b2b-company-settings", "Failed to call ????");
  }
};

const filteredData = computed(() => {
  const tempData = [...data.value];
  return tempData.filter((row) => {
    return filterOnColumns.value.every((filter) => {
      const columnValue = row[filter.column];

      if (typeof columnValue === "boolean") {
        return columnValue === (filter.value.toLowerCase() === "true");
      }

      return columnValue.toLowerCase().includes(filter.value.toLowerCase());
    });
  });
});

const sortData = (
  switchSortingState: boolean,
  firstClickOnNewHeader: boolean,
  tempData: DataResponse
): DataResponse => {
  const currentColumnSortState = setCurrentColumnSortState(
    firstClickOnNewHeader,
    switchSortingState
  );
  resetAndSetActiveSortingIconName();
  if (currentColumnSortState.sortingFunction) {
    const columnKey = columnTranslationToAttribute.value[sortableColumns.value.active.activeName];
    tempData.sort((row1, row2) => currentColumnSortState.sortingFunction(row1, row2, columnKey));
  }

  return tempData;
};

const paginateData = (tempData: DataResponse): DataResponse => {
  const startIndex = (pagination.value.page - 1) * pagination.value.pageSize;
  const endIndex = startIndex + pagination.value.pageSize;

  return tempData.slice(startIndex, endIndex);
};

const onFilterColumnInput = ({
  detail,
}: {
  detail: {
    value: string;
    column: string;
  };
}) => {
  const column = columnTranslationToAttribute.value[detail.column];

  const foundIndex = filterOnColumns.value.findIndex((obj) => obj.column === column);

  // Check if we are already filtering on the column
  if (foundIndex >= 0) {
    if (detail.value === "") {
      // Remove the column from filterOnColumns when the value is empty
      filterOnColumns.value.splice(foundIndex, 1);
    } else {
      // Filter on the column
      filterOnColumns.value[foundIndex].value = detail.value;
    }
  } else {
    // Filter on the column
    filterOnColumns.value.push({
      column: column,
      value: detail.value,
    });
  }
  changePage(1);
};

const onRowCheckboxClicked = ({ detail }: { detail: number | "CHECKBOX_ALL" }) => {
  if (typeof detail === "string" && detail === "CHECKBOX_ALL") {
    handleAllCheckBoxesClicked();
  } else {
    handleRowCheckBoxClicked(detail);
  }
};

const onActionButtonClickedEvent = ({ detail }: { detail: "activate" | "deactivate" }) => {
  openDrawer(detail);
};

const handleAllCheckBoxesClicked = () => {
  const allAreSelected = filteredData.value.every((row) => selectedOrgs.value.includes(row));

  if (allAreSelected) {
    filteredData.value.forEach((row) => {
      const index = selectedOrgs.value.findIndex(
        (obj) => obj.organizationNumber === row.organizationNumber
      );
      selectedOrgs.value.splice(index, 1);
    });
  } else {
    filteredData.value.forEach((row) => {
      if (!selectedOrgs.value.includes(row)) {
        selectedOrgs.value.push(row);
      }
    });
  }
};

const handleRowCheckBoxClicked = (rowNr: number) => {
  const checkedOrg = tableData.value[rowNr];

  if (selectedOrgs.value.includes(checkedOrg)) {
    selectedOrgs.value = selectedOrgs.value.filter((org) => org !== checkedOrg);
  } else {
    selectedOrgs.value.push(checkedOrg);
  }
};

const onPaginationChange = ({
  detail,
}: {
  detail: {
    page: number;
    pageSize: PageSizes;
  };
}) => {
  changePage(detail.page, detail.pageSize);
};

const changePage = (page: number, pageSize?: PageSizes) => {
  pagination.value.page = page;
  if (pageSize) {
    pagination.value.pageSize = pageSize;
  }
  generateTableData(false);
};

const headerClicked = (event: CustomEvent) => {
  sortTableData(event.detail);
};

const sortTableData = (columnName: string): void => {
  if (sortableColumns.value.active.activeName === columnName) {
    generateTableData();
  } else {
    sortableColumns.value.active.activeName = columnName;
    generateTableData(true, true);
  }
};

const resetAndSetActiveSortingIconName = () => {
  tableHeaders.value.map((header: ITableHeader) => {
    header.sortable.sortIconName =
      header.title === sortableColumns.value.active.activeName
        ? sortableColumns.value.active.iconName
        : "sorter";
  });
};

const setCurrentColumnSortState = (firstClickOnNewHeader: boolean, switchSortingState: boolean) => {
  let currentColumnSortState = columnSortStates[sortableColumns.value.active.state];
  if (firstClickOnNewHeader) {
    currentColumnSortState = columnSortStates["descending"];
    sortableColumns.value.active.state = currentColumnSortState.state;
    sortableColumns.value.active.iconName = currentColumnSortState.iconName;
  } else if (switchSortingState) {
    currentColumnSortState = columnSortStates[currentColumnSortState.next];
    sortableColumns.value.active.state = currentColumnSortState.state;
    sortableColumns.value.active.iconName = currentColumnSortState.iconName;
  }
  return currentColumnSortState;
};

const openDrawer = (action: "activate" | "deactivate") => {
  isDrawerOpen.value = true;
  drawerAction.value = action;
};

const closeDrawer = () => {
  isDrawerOpen.value = false;
};

const trackEmptyTable = () => {
  trackAnalytics("company_settings_telia_at_work_progress", {
    error_message: "no results",
  });
};

watch(
  () => filteredDataIsEmpty.value,
  () => {
    if (filteredDataIsEmpty.value) {
      trackEmptyTable();
    }
  }
);
</script>

<style lang="scss" scoped>
// section div:first-of-type {
//   margin-bottom: var(--purpur-spacing-400);
// }

.telia-at-work-section {
  display: flex;
  padding-bottom: var(--purpur-spacing-400);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--purpur-spacing-400);
  align-self: stretch;
}

.telia-at-work-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--purpur-spacing-200);
  align-self: stretch;
  max-width: 60rem;
}

.paginator-skeletons {
  margin-top: 1rem;
  margin-left: 1rem;
  height: 2.9rem;
  width: 60rem;
  display: flex;
  justify-content: space-between;

  telia-skeleton:first-of-type {
    width: 35%;
  }

  telia-skeleton:nth-of-type(2) {
    width: 25%;
  }
}
</style>
