<template>
  <div>
    <div class="telia-notification__wrapper" v-if="showInformationNotification">
      <telia-notification
        t-id="invoice-email-info"
        status="information"
        :button-text="t('mybusiness.close')"
        :button-aria-label="t('COMPANY_SETTINGS.INVOICE_EMAIL.INFO_NOTIFICATION.BUTTON_ARIA')"
        heading-tag="h2"
        @vocaClose="closeInformationNotification"
      >
        <span slot="heading">{{
          t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_NOTIFICATION.HEADER")
        }}</span>
        <telia-text-spacing slot="content">
          <telia-p
            >{{ t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_NOTIFICATION.MESSAGE") }}
            <b2x-sspa-link variant="text" :href="billingAccountUrl" t-id="invoice-email-info-link">
              {{ t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_NOTIFICATION.MESSAGE_LINK") }}
            </b2x-sspa-link>
          </telia-p>
        </telia-text-spacing>
      </telia-notification>
    </div>
    <div class="telia-notification__wrapper" v-if="showSuccessNotification">
      <telia-notification
        t-id="invoice-email-success"
        status="success"
        :button-text="t('mybusiness.close')"
        :button-aria-label="t('COMPANY_SETTINGS.INVOICE_EMAIL.SUCCESS_NOTIFICATION.BUTTON_ARIA')"
        heading-tag="h2"
        @vocaClose="closeSuccessNotification"
      >
        <span slot="heading">{{
          t("COMPANY_SETTINGS.INVOICE_EMAIL.SUCCESS_NOTIFICATION.HEADER")
        }}</span>
        <telia-text-spacing slot="content">
          <telia-p>{{ successMessage }} </telia-p>
        </telia-text-spacing>
      </telia-notification>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../../src/locale";
import CompanySettingsStateService from "../../services/company-settings.state.service";
import "@telia/b2x-sspa-link";

export default {
  name: "invoiceEmailInformation",
  mixins: [translateMixin],
  inject: ["invoiceEmailStateService"],
  data() {
    return {
      invoiceEmailStateServiceState: this.invoiceEmailStateService.state,
      companySettingsStateService: null,
    };
  },
  async created() {
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
  },
  computed: {
    showInformationNotification() {
      return this.invoiceEmailStateServiceState.layout.showInformationNotification;
    },
    showSuccessNotification() {
      return this.invoiceEmailStateServiceState.layout.successNotification.show;
    },
    successMessage() {
      return this.invoiceEmailStateServiceState.layout.successNotification.message;
    },
    billingAccountUrl() {
      return `/foretag/mybusiness/${this.companySettingsStateService?.state.loggedInUser.scopeId}/fakturor/hantera-fakturering`;
    },
  },
  methods: {
    closeInformationNotification() {
      this.invoiceEmailStateService.closeInformationNotification();
    },
    closeSuccessNotification() {
      this.invoiceEmailStateService.closeSuccessNotification();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";

div.telia-notification__wrapper {
  margin-bottom: $telia-spacing-16;
}
</style>
