<template>
  <b2x-modal
    :is-open="showInfoModal"
    @closeModal="closeModal"
    show-close-button="false"
    t-id="email-info-modal"
  >
    <div slot="modal-body">
      <telia-heading variant="title-300" tag="h3" t-id="email-info-modal-heading">{{
        header
      }}</telia-heading>
      <telia-p t-id="email-info-modal-message">{{ message }}</telia-p>
    </div>
    <div slot="button-footer" class="button-footer">
      <template v-if="isUsedOrHasErrors">
        <telia-button
          variant="primary"
          @click="closeModal"
          t-id="email-info-modal-agree-button"
          type="button"
          >{{ this.t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.USED.BUTTON.AGREE") }}</telia-button
        >
      </template>
      <template v-if="!isUsedOrHasErrors">
        <telia-button
          variant="tertiary-purple"
          @click="closeModal"
          t-id="email-info-modal-abort-button"
          type="button"
          >{{
            this.t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.REMOVE.BUTTONS.ABORT")
          }}</telia-button
        >
        <telia-button
          variant="primary"
          @click="closeInfoModalAndRemoveEmail"
          t-id="email-info-modal-remove-button"
          type="button"
          >{{
            this.t("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.REMOVE.BUTTONS.REMOVE")
          }}</telia-button
        >
      </template>
    </div>
  </b2x-modal>
</template>

<script>
import "@telia/b2x-modal";
import { translateMixin } from "../../../src/locale";

export default {
  name: "emailRecipientInfoModal",
  inject: ["invoiceEmailStateService"],
  mixins: [translateMixin],
  data() {
    return {
      invoiceEmailStateServiceState: this.invoiceEmailStateService.state,
    };
  },
  methods: {
    closeModal() {
      this.invoiceEmailStateService.closeInfoModal();
    },
    closeInfoModalAndRemoveEmail() {
      this.invoiceEmailStateService.closeInfoModalAndRemoveEmail();
    },
  },
  computed: {
    header() {
      return this.invoiceEmailStateServiceState.layout.infoModal.header;
    },
    message() {
      return this.invoiceEmailStateServiceState.layout.infoModal.message;
    },
    isUsedOrHasErrors() {
      return (
        this.invoiceEmailStateServiceState.data.selectedEmail?.used ||
        this.invoiceEmailStateServiceState.layout.error.removingEmail
      );
    },
    showInfoModal() {
      return this.invoiceEmailStateServiceState.layout.infoModal.show;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/typography/variables.scss";

// TODO: remove after  b2x-modal z-index has been fixed.
b2x-modal :deep(.modal-wrapper) {
  z-index: 100010;
}

div.modal-body {
  padding: $telia-spacing-24;
  telia-heading {
    padding-bottom: $telia-spacing-12;
  }
}
div.button-footer {
  display: flex;
  justify-content: flex-end;
  padding: $telia-spacing-24;
}
</style>
