import { trackGa4, getGa4DefaultParams } from "@telia/b2b-analytics";
import type { TeliaEventName, TeliaEventParams } from "@telia/b2x-google-analytics";

const sharedValues = {
  ...getGa4DefaultParams(),
  logged_in: true,
  page_type: "manage",
};

type TempTeliaEventName =
  | TeliaEventName
  | "company_settings_telia_at_work_initate"
  | "company_settings_telia_at_work_progress"
  | "company_settings_telia_at_work_fail"
  | "company_settings_telia_at_work_complete";

export const trackAnalytics = (eventName: TempTeliaEventName, eventParams: TeliaEventParams) => {
  //@ts-expect-error TODO: update b2b-analytics eventnames when I have gotten approval on the events sent.
  trackGa4(eventName, {
    ...sharedValues,
    ...eventParams,
  });
};

export const trackLinkInteraction = (
  click_action: string,
  click_text: string,
  click_type: string
) => {
  trackGa4("link_interaction", {
    ...sharedValues,
    click_action,
    click_text,
    click_type,
  });
};
