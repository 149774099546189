import data from "../../tests/cypress/fixtures/getTeliaAtWorkOrganizations.json";
//TODO: Refactor the types when BE is ready
export type DataRow = {
  organizationName: string;
  organizationNumber: string;
  tscid: string;
  active: boolean;
};
export type DataResponse = DataRow[];

//TODO: Rename the method when BE is ready
export const getData = async (): Promise<DataResponse> => {
  //TODO: Swap out for rest client when BE is ready
  // const response = await fetch("/corpCompanyConfigurationGetCall");
  // const json = await response.json();

  // return json as DataResponse;
  return data as DataResponse;
};

export const postData = async (body: { tscids: string[]; allowed: boolean }): Promise<void> => {
  const response = await fetch("/corpCompanyConfigurationPostCall", {
    method: "POST",
    body: JSON.stringify(body),
  });

  return await response.json();
};
