import { ITableHeader } from "../typings/table";

export function createTableHeaders(t: (key: string) => string): ITableHeader[] {
  return [
    {
      title: t("mybusiness.company_settings.telia_at_work.table.headers.company_name"),
      type: "text",
      isChecked: true,
      sortable: {
        sortIconName: "sorter",
      },
      filterable: {
        type: "text",
        label: t(
          "mybusiness.company_settings.telia_at_work.table.filters.placeholders.company_name"
        ),
      },
    },
    {
      title: t("mybusiness.company_settings.telia_at_work.table.headers.organization_number"),
      type: "text",
      isChecked: true,
      sortable: {
        sortIconName: "sorter",
      },
      filterable: {
        type: "text",
        label: t(
          "mybusiness.company_settings.telia_at_work.table.filters.placeholders.organization_number"
        ),
      },
    },
    {
      title: t("mybusiness.company_settings.telia_at_work.table.headers.status"),
      type: "status-badge",
      isChecked: true,
      sortable: {
        sortIconName: "sorter",
      },
      filterable: {
        type: "enum",
        label: "Search",
        values: [
          {
            value: true,
            displayName: t("mybusiness.company_settings.telia_at_work.status.activated"),
          },
          {
            value: false,
            displayName: t("mybusiness.company_settings.telia_at_work.status.deactivated"),
          },
        ],
        noSelectionDisplayName: t(
          "mybusiness.company_settings.telia_at_work.table.filter.select.default"
        ),
      },
    },
  ];
}
