<template>
  <div>
    <b2x-inline-dropdown
      type="text"
      t-id="invoice-email-organisation-selector"
      heading-element="telia-p"
      heading-variant="paragraph-100"
      icon-size="sm"
      :value="selectedOrganisation"
      :options="JSON.stringify(organisationFilterOptions)"
      @optionSelected="changeOrganisation"
    ></b2x-inline-dropdown>
  </div>
</template>

<script>
import { translateMixin } from "../../../src/locale";
import CompanySettingsStateService from "../../services/company-settings.state.service";
import "@telia/b2x-inline-dropdown";

export default {
  name: "emailRecipientsOrgSelector",
  mixins: [translateMixin],
  inject: ["invoiceEmailStateService"],
  data() {
    return {
      companySettingsStateService: null,
      organisations: null,
    };
  },
  async created() {
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
  },
  computed: {
    organisationFilterOptions() {
      return this.invoiceEmailStateService.getOrganisations();
    },
    selectedOrganisation() {
      if (this.invoiceEmailStateService.state.data.selectedOrganisation.organizationNumber !== "") {
        return this.invoiceEmailStateService.state.data.selectedOrganisation.organizationNumber;
      }
      return this.organisationFilterOptions[0].value;
    },
  },
  methods: {
    changeOrganisation(event) {
      this.invoiceEmailStateService.setSelectedOrganisation(event.detail);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  height: 4.6rem;
  padding-top: 1.4rem;

  b2x-inline-dropdown {
    position: absolute;
    --inline-dropdown-min-width: 25rem;
  }
}
</style>
