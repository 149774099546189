import { IEmailRecipient, IInvoiceEmailState, ITableHeader } from "./interfaces/IInvoiceEmailState";
import { InvoiceEmailRestService } from "./invoice-email.rest.service";
import CompanySettingsStateService from "./company-settings.state.service";
import {
  action,
  category,
  label,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";
import { logError } from "@telia/b2x-logging";
import { IOrganisation, IOrganisationOption } from "./interfaces/ICompanySettingsState";

export default class InvoiceEmailStateService {
  public state: IInvoiceEmailState;
  private invoiceEmailRestService: InvoiceEmailRestService;
  private userOrganisationStateService: CompanySettingsStateService;
  private translate: (key: string, optionalObject?: Record<string, unknown>) => string;
  private analytics: {
    action: typeof action;
    category: typeof category;
    label: typeof label;
  };
  private trackEvent: typeof TrackEvent;

  constructor(translate: (key: string, optionalObject?: Record<string, unknown>) => string) {
    this.translate = translate;
    this.analytics = {
      action,
      category,
      label,
    };
    this.trackEvent = TrackEvent;
    this.userOrganisationStateService = CompanySettingsStateService.getInstance();
    this.state = this._createState();
    this.invoiceEmailRestService = new InvoiceEmailRestService();
  }

  public getHeaders(): ITableHeader[] {
    return [
      {
        title: this.translate("mybusiness.invoice.deliveryMethod.deliveryEmail"),
        type: "text",
      },
      {
        title: this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.USED_IN_INVOICE"),
        type: "text",
      },
      {
        title: "",
        type: "button-text",
      },
    ];
  }

  public openInfoModalAndSetSelectedEmail(contactPointId: string): void {
    this.state.layout.successNotification = {
      message: "",
      show: false,
    };
    this.state.data.selectedEmail = this.state.data.emailList.find((email: IEmailRecipient) => {
      return contactPointId === email.contactPointId;
    });

    if (this.state.data.selectedEmail?.used) {
      this._populateInfoModal(
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.USED.HEADER"),
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.USED.MESSAGE", {
          numberOfAccounts: this.state.data.selectedEmail.numberOfAccounts,
        })
      );
      this._trackEventInvoiceEmail("Kan ej tas bort", this.analytics.action.CLICK);
    } else {
      this._populateInfoModal(
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.REMOVE.HEADER"),
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.REMOVE.MESSAGE", {
          email: this.state.data.selectedEmail?.email,
          organisation: this.state.data.selectedOrganisation.name,
        })
      );
    }
    this._trackEventInvoiceEmail("Ta bort e-postmottagare", this.analytics.action.INITIATED);
  }

  public closeInfoModal(): void {
    this.state.layout.infoModal = {
      header: "",
      message: "",
      show: false,
    };
    if (!this.state.data.selectedEmail?.used) {
      // may be a bug here, this is tracked even when successfully deleting an e-mail
      this._trackEventInvoiceEmail("Avbryt", this.analytics.action.CLICK);
    }
  }

  public closeInformationNotification(): void {
    this.state.layout.showInformationNotification = false;
  }
  public closeSuccessNotification(): void {
    this.state.layout.successNotification.show = false;
  }

  public async closeInfoModalAndRemoveEmail(): Promise<void> {
    await this._tryToRemoveEmail(this.state.data.selectedEmail?.contactPointId);
  }

  public filterEmails(filterValue: string): void {
    this.state.data.displayEmailList = this._mapDisplayEmailList(
      this.state.data.emailList.filter((email: IEmailRecipient) => {
        return email.email.toLowerCase().includes(filterValue.toLowerCase());
      })
    );
  }

  public getOrganisations(): IOrganisationOption[] {
    const selectedOrganisationNumber =
      this.state.data.selectedOrganisation.organizationNumber || "";
    const mappedAndSortedOrgOptions = this.userOrganisationStateService.state.organisations.list
      .map((org: IOrganisation) => ({
        label: org.name,
        value: org.organizationNumber,
        selected: selectedOrganisationNumber === org.organizationNumber,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    if (mappedAndSortedOrgOptions.length === 1) {
      this.state.data.selectedOrganisation = this.userOrganisationStateService.state.organisations.list[0];
      this._fetchAvailableEmails();
    }
    return [
      {
        label: this.translate("mybusiness.orgPicker.organisationSelect"),
        value: this.translate("mybusiness.orgPicker.organisationSelect"),
        selected: selectedOrganisationNumber === "",
        disabled: true,
      },
      ...mappedAndSortedOrgOptions,
    ];
  }
  public async setSelectedOrganisation(orgNumber: string): Promise<void> {
    const foundOrg = this.userOrganisationStateService.state.organisations.list.find((org) => {
      return org.organizationNumber === orgNumber;
    });
    if (foundOrg) {
      this.state.data.selectedOrganisation = foundOrg;
      await this._fetchAvailableEmails();
    }
  }

  private async _fetchAvailableEmails(): Promise<void> {
    if (this.state.data.selectedOrganisation) {
      try {
        this.state.layout.loading = true;
        this.state.layout.error.fetchingEmail = false;
        this.state.data.emailList = await this.invoiceEmailRestService.fetchAvailableEmails(
          this.userOrganisationStateService.state.loggedInUser.scopeId,
          this.state.data.selectedOrganisation.tscid
        );
        this.state.data.displayEmailList = this._mapDisplayEmailList(this.state.data.emailList);
      } catch (e) {
        this._logError("Failed to fetch available emails");
        this.state.layout.error.fetchingEmail = true;
      } finally {
        this.state.layout.loading = false;
      }
    }
  }

  private _mapDisplayEmailList(
    emailListToMap
  ): [
    string,
    number,
    { rowNumber: string; content: string; attrs: { leftIcon?: { name: string }; variant: string } }
  ][] {
    return emailListToMap.map((email: IEmailRecipient) => {
      return [
        email.email,
        email.numberOfAccounts,
        this._setButtonProperties(email.used, email.contactPointId),
      ];
    });
  }

  private _createState(): IInvoiceEmailState {
    return {
      data: {
        emailList: [],
        displayEmailList: [],
        selectedEmail: undefined,
        selectedOrganisation: { tscid: "", organizationNumber: "", name: "" },
      },
      layout: {
        loading: false,
        showInformationNotification: true,
        successNotification: {
          message: "",
          show: false,
        },
        error: {
          fetchingEmail: false,
          removingEmail: false,
        },
        infoModal: {
          header: "",
          message: "",
          show: false,
        },
      },
    };
  }

  private _setButtonProperties(
    isEmailUsed,
    contractPointId
  ): {
    rowNumber: string;
    content: string;
    attrs: { variant: string; leftIcon?: { name: string } };
  } {
    const leftIcon = isEmailUsed ? undefined : { name: "delete" };
    return {
      content: this.translate(
        isEmailUsed
          ? "COMPANY_SETTINGS.INVOICE_EMAIL.CANT_REMOVE"
          : "COMPANY_SETTINGS.INVOICE_EMAIL.REMOVE"
      ),
      attrs: { variant: "expressive", leftIcon },
      rowNumber: contractPointId,
    };
  }

  private _populateInfoModal(header: string, message: string): void {
    this.state.layout.infoModal.header = header;
    this.state.layout.infoModal.message = message;
    this.state.layout.infoModal.show = true;
  }

  private async _tryToRemoveEmail(contactPointId): Promise<void> {
    try {
      this.state.layout.error.removingEmail = false;
      await this.invoiceEmailRestService.removeEmail(
        this.userOrganisationStateService.state.loggedInUser.scopeId,
        this.state.data.selectedOrganisation.tscid,
        contactPointId
      );
      this._showSuccessNotification();
      this.closeInfoModal();
      await this._fetchAvailableEmails();
      this._trackEventInvoiceEmail("Ta bort e-postmottagare", this.analytics.action.COMPLETED);
    } catch (e) {
      this._logError("Failed to remove email");
      this.state.layout.error.removingEmail = true;
      this._populateInfoModal(
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.ERROR_REMOVING.HEADER"),
        this.translate("COMPANY_SETTINGS.INVOICE_EMAIL.INFO_MODAL.ERROR_REMOVING.MESSAGE")
      );
    }
  }
  private _showSuccessNotification() {
    this.state.layout.successNotification.message = this.translate(
      "COMPANY_SETTINGS.INVOICE_EMAIL.SUCCESS_NOTIFICATION.MESSAGE",
      {
        email: this.state.data.selectedEmail?.email,
        organisation: this.state.data.selectedOrganisation?.name,
      }
    );
    this.state.layout.successNotification.show = true;
  }

  private _logError(message: string): void {
    logError("b2b-company-settings", message);
  }

  private _trackEventInvoiceEmail(label: string, action: string): void {
    const category = this.analytics.category.COMPANY_SETTINGS;
    this.trackEvent(category, action, label);
  }
}
