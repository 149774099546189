<template>
  <div>
    <telia-div t-id="datanet-shareorder-section" v-show="!loading">
      <telia-row>
        <telia-p>{{ t("COMPANY_SETTINGS.DATANET_SHAREDORDERS.TITLE") }}</telia-p>
      </telia-row>
      <telia-row>
        <telia-radio-button
          name="datanet-shareorders-radio"
          :label="t('COMPANY_SETTINGS.DATANET_SHAREDORDERS.NO')"
          :checked="!shareOrders"
          @change="radioChange"
        ></telia-radio-button>
      </telia-row>
      <telia-row>
        <telia-radio-button
          name="datanet-shareorders-radio"
          :label="t('COMPANY_SETTINGS.DATANET_SHAREDORDERS.YES')"
          :checked="shareOrders"
          @change="radioChange"
        ></telia-radio-button>
      </telia-row>
    </telia-div>
    <telia-div v-show="loading">
      <telia-skeleton class="datanet-shareorders__sentence-skeleton"></telia-skeleton>
      <telia-row>
        <telia-skeleton class="datanet-shareorders__radio-skeleton"></telia-skeleton>
        <telia-skeleton class="datanet-shareorders__sentence-skeleton"></telia-skeleton>
      </telia-row>
      <telia-row>
        <telia-skeleton class="datanet-shareorders__radio-skeleton"></telia-skeleton>
        <telia-skeleton class="datanet-shareorders__sentence-skeleton"></telia-skeleton>
      </telia-row>
    </telia-div>
  </div>
</template>

<script>
import { getScopeId } from "@telia/b2b-customer-scope";
import { corpCompanySettingsDatanet } from "@telia/b2b-rest-client";
import { translateSetup, translateMixin } from "../locale";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

const Errors = Object.freeze({
  SUBMIT: "SUBMIT_ERROR",
});

export default {
  name: "datanetShareorders",
  mixins: [translateMixin],
  data() {
    return {
      shareOrders: null,
      loading: true,
      scopeId: "",
      Errors,
    };
  },
  async created() {
    await translateSetup(["mybusiness"]);
    this.scopeId = await getScopeId();
    await this.getShareOrders();
  },
  methods: {
    async getShareOrders() {
      try {
        const resp = await corpCompanySettingsDatanet.ExternalSharedOrdersControllerService.getDataNetSharedOrdersSettingUsingGet(
          this.scopeId
        );
        this.shareOrders = resp.allowSharedOrders;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.generalError = true;
      }
    },
    async radioChange() {
      this.shareOrders = !this.shareOrders;

      try {
        await corpCompanySettingsDatanet.ExternalSharedOrdersControllerService.setDataNetSharedOrdersSettingUsingPut(
          this.formatDatanetShareOrder(),
          this.scopeId
        );

        analytics.trackEvent(analytics.category.COMPANY_SETTINGS, analytics.action.CLICK, "Yes/No");
      } catch {
        this.errorMessage = this.Errors.SUBMIT;
        this.isSubmitting = false;
        return;
      }
    },
    formatDatanetShareOrder() {
      return {
        allowSharedOrders: this.shareOrders,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

telia-row {
  display: flex;
  align-items: center;
  padding: $telia-spacing-4 0;
}

.datanet-shareorders__sentence-skeleton {
  width: 45rem;
  height: 2rem;
  margin-bottom: $telia-spacing-4;
}

.datanet-shareorders__radio-skeleton {
  width: 3rem;
  height: 2rem;
  margin-right: 2rem;
  margin-top: $telia-spacing-4;
  margin-bottom: $telia-spacing-4;
}
</style>
