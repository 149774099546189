<template>
  <b2x-drawer
    t-id="telia-at-work-drawer"
    :heading="drawerHeader"
    position="right"
    :is-open="isOpen"
    @drawerClose="closeDrawer"
  >
    <component
      v-if="isOpen"
      :is="getComponent"
      :orgs="orgs"
      :fullWidthButton="fullWidthButton"
      @successfullyUpdatedOrganizations="() => (successfullyUpdatedOrganizations = true)"
    />
  </b2x-drawer>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";

import { translateMixin } from "../../locale";

import TeliaAtWorkActivate from "./telia-at-work-activate.vue";
import TeliaAtWorkDeactivate from "./telia-at-work-deactivate.vue";

import { DataResponse } from "../../services/corpCompanyConfiguration";

import { trackAnalytics } from "../../utils/trackGA4";

type Action = "activate" | "deactivate";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  action: {
    type: String as () => Action,
    required: true,
  },
  selectedOrgs: {
    type: Array as () => DataResponse,
    required: true,
  },
});

const emit = defineEmits<{ (e: "drawerClose"): void }>();

const t = translateMixin.methods.t;
const fullWidthButton = ref(false);
const successfullyUpdatedOrganizations = ref(false);

onBeforeMount(() => {
  window.addEventListener("resize", onWindowResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onWindowResize);
});

const drawerHeader = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return props.action === "activate"
      ? t("mybusiness.company_settings.telia_at_work.drawer.activate.succesful_activation_header")
      : t(
          "mybusiness.company_settings.telia_at_work.drawer.deactivate.succesful_deactivation_header"
        );
  }

  return props.action === "activate"
    ? t("mybusiness.company_settings.telia_at_work.drawer.activate.header")
    : t("mybusiness.company_settings.telia_at_work.drawer.deactivate.header");
});

const activeOrgs = computed(() => {
  return props.selectedOrgs.filter((org) => org.active);
});

const inActiveOrgs = computed(() => {
  return props.selectedOrgs.filter((org) => !org.active);
});

const getComponent = computed(() => {
  return props.action === "activate" ? TeliaAtWorkActivate : TeliaAtWorkDeactivate;
});

const orgs = computed(() => {
  return props.action === "activate" ? inActiveOrgs.value : activeOrgs.value;
});

const onWindowResize = () => {
  fullWidthButton.value = window.innerWidth < 599;
};

const closeDrawer = () => {
  successfullyUpdatedOrganizations.value = false;
  emit("drawerClose");
};

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      trackAnalytics("company_settings_telia_at_work_progress", {
        access_action: props.action,
      });
    }
  }
);
</script>

<style scoped></style>
