<template>
  <telia-p
    t-id="telia-at-work-drawer-description"
    class="activation-description"
    variant="paragraph-200"
    disableHyphenation
  >
    {{ getDescription }}
  </telia-p>
  <template v-if="orgCount > 0 && !successfullyUpdatedOrganizations">
    <telia-p
      t-id="telia-at-work-drawer-activation_message"
      class="activation-message"
      variant="paragraph-200"
    >
      {{
        t("mybusiness.company_settings.telia_at_work.drawer.activate.activation_message", {
          orgCount: orgCount,
        })
      }}
    </telia-p>
    <telia-link variant="standalone" class="terms-link" href="" @click="trackAnalyticsLinkInteract">
      {{ linkText }}
      <telia-icon name="external" slot="right" size="sm"></telia-icon>
    </telia-link>
    <telia-form @submit.prevent="submitActivateForm">
      <telia-checkbox
        class="terms-checkbox"
        required
        :additional="
          t('mybusiness.company_settings.telia_at_work.drawer.activate.terms.checkbox_additional')
        "
        :requiredErrorMessage="
          t(
            'mybusiness.company_settings.telia_at_work.drawer.activate.terms.checkbox_required_message'
          )
        "
        >{{
          t("mybusiness.company_settings.telia_at_work.drawer.activate.terms.checkbox")
        }}</telia-checkbox
      >
      <b2x-loading-button
        variant="primary"
        type="submit"
        :is-loading="submitting"
        :full-width="fullWidthButton"
        >{{
          t("mybusiness.company_settings.telia_at_work.drawer.activate.button")
        }}</b2x-loading-button
      >
    </telia-form>
    <telia-notification
      v-if="showErrorNotification"
      status="error"
      :button-text="t('mybusiness.close')"
      heading-tag="h3"
      @vocaClose="() => (showErrorNotification = false)"
    >
      <span slot="heading">{{
        t("mybusiness.company_settings.telia_at_work.error_notification.header")
      }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{
          t("mybusiness.company_settings.telia_at_work.activate.error_notification.body")
        }}</telia-p>
      </telia-text-spacing>
    </telia-notification>
  </template>
  <template v-if="successfullyUpdatedOrganizations">
    <DownloadPdfButton />
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { logError } from "@telia/b2x-logging";

import { translateMixin } from "../../locale";

import DownloadPdfButton from "./download-pdf-button.vue";

import { DataResponse, postData } from "../../services/corpCompanyConfiguration";

import { trackAnalytics, trackLinkInteraction } from "../../utils/trackGA4";

const props = defineProps<{
  orgs: DataResponse;
  fullWidthButton: boolean;
}>();

const emit = defineEmits<{ (e: "successfullyUpdatedOrganizations"): void }>();

const t = translateMixin.methods.t;
const showErrorNotification = ref(false);
const submitting = ref(false);
const successfullyUpdatedOrganizations = ref(false);

const linkText = t("mybusiness.company_settings.telia_at_work.drawer.activate.terms.link");

onMounted(() => {
  submitting.value = false;
  successfullyUpdatedOrganizations.value = false;
});

const getDescription = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return t(
      "mybusiness.company_settings.telia_at_work.drawer.activate.succesful_activation_description"
    );
  }

  return orgCount.value === 0
    ? t("mybusiness.company_settings.telia_at_work.drawer.activate.no_organizations_selected")
    : t("mybusiness.company_settings.telia_at_work.drawer.activate.description", {
        orgCount: orgCount.value,
      });
});

const orgCount = computed(() => {
  return props.orgs.length;
});

const submitActivateForm = async () => {
  try {
    submitting.value = true;
    const tscids = props.orgs.map((org) => org.tscid);
    const body = {
      tscids,
      allowed: true,
    };
    await postData(body);

    successfullyUpdatedOrganizations.value = true;
    trackAnalytics("company_settings_telia_at_work_complete", {
      access_action: "activate",
    });
    emit("successfullyUpdatedOrganizations");
  } catch (error) {
    showErrorNotification.value = true;
    logError("b2b-company-settings", "Failed to call ????");
    trackAnalytics("company_settings_telia_at_work_fail", {
      access_action: "activate",
    });
  } finally {
    submitting.value = false;
  }
};

const trackAnalyticsLinkInteract = () => {
  trackLinkInteraction("click", linkText, "text_link");
};
</script>

<style lang="scss" scoped>
.activation-description {
  margin-bottom: var(--purpur-spacing-400);
}

.activation-message {
  margin-bottom: var(--purpur-spacing-400);
}

.terms-link {
  margin-bottom: var(--purpur-spacing-200);
}

.terms-checkbox {
  margin-bottom: var(--purpur-spacing-400);
}
</style>
