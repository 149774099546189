<template>
  <FlagProvider v-if="client" :unleash-client="client" :start-client="false">
    <B2bCompanySettings></B2bCompanySettings>
  </FlagProvider>
</template>

<script setup lang="ts">
import { Ref } from "vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import B2bCompanySettings from "./b2b-company-settings.vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm.js";

const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-company-settings");
</script>
