<template>
  <div t-id="e-sim-watch-section">
    <div class="e-sim-watch__info-wrapper">
      <telia-p disable-hyphenation>
        {{ t("COMPANY_SETTINGS.E_SIM_WATCH.DESCRIPTION") }}
      </telia-p>
      <div v-if="showNonEditableInfo" class="e-sim-watch__notification-wrapper">
        <telia-notification status="information" heading-tag="h2">
          <telia-p slot="heading">
            <strong>{{ t("COMPANY_SETTINGS.E_SIM_WATCH.INFO_HEADING_TEMP_DISABLED") }} </strong>
          </telia-p>
          <telia-p slot="content" disable-hyphenation>
            {{ t("COMPANY_SETTINGS.E_SIM_WATCH.INFO_CONTENT_TEMP_DISABLED") }}
          </telia-p>
        </telia-notification>
      </div>
      <div class="e-sim-watch__notification-wrapper">
        <telia-notification
          v-show="fetchOrganisationsError"
          t-id="fetch-organisations-error"
          class="e-sim-watch__notification"
          status="warning"
          heading-tag="h2"
        >
          <telia-p slot="heading" disable-hyphenation>
            {{ t("COMPANY_SETTINGS.E_SIM_WATCH.FETCH_ORGANISATIONS_ERROR") }}
          </telia-p>
        </telia-notification>
      </div>
    </div>
    <div v-show="!fetchOrganisationsError" class="e-sim-watch__table-wrapper">
      <div class="e-sim-watch__dropdown-wrapper">
        <telia-skeleton v-show="loading" class="e-sim-watch__dropdown-skeleton"></telia-skeleton>
        <b2x-inline-dropdown
          v-show="!loading && organisationSelectorOptions.length"
          class="e-sim-watch__dropdown"
          t-id="organisation-selector-dropdown"
          :options="JSON.stringify(organisationSelectorOptions)"
          :value="selectedOrganisation"
          heading-element="telia-p"
          heading-variant="paragraph-100"
          icon-size="sm"
          @optionSelected="onOrganisationSelected($event.detail)"
        ></b2x-inline-dropdown>
      </div>
      <b2x-controlled-table
        t-id="e-sim-watch-table"
        :columns="JSON.stringify(columns)"
        :data="JSON.stringify(displayedRows)"
        :message="tableMessage"
        show-filters="false"
        :is-loading="loading"
        @buttonTextClicked="onHandleChangeButtonClick"
        @columnClick="onColumnClick"
      ></b2x-controlled-table>
      <e-sim-watch-drawer
        t-id="e-sim-watch-drawer"
        :isDrawerOpen="isDrawerOpen"
        @closeDrawer="closeDrawer"
        @agreementUpdated="agreementUpdated"
        :agreement="selectedAgreement"
      ></e-sim-watch-drawer>
      <b2x-paginator
        v-show="!loading && !fetchAgreementsError"
        t-id="e-sim-watch-paginator"
        @paginationChange="onPaginationChange"
        :page-sizes="'[10, 25, 50, 100]'"
        :list-length="rows.length"
      />
    </div>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import { logError } from "@telia/b2x-logging";
import "@telia/b2x-inline-dropdown";
import CompanySettingsStateService from "../services/company-settings.state.service";
import { corpMdsPolicy } from "@telia/b2b-rest-client";
import eSimWatchDrawer from "./e-sim-watch-drawer.vue";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

const POLICY_TYPES = {
  MANAGER_ONLY: "MANAGER_ONLY",
  ALLOW_ALL: "ALLOW_ALL",
  PRIVATE_ONLY: "PRIVATE_ONLY",
  NONE: "NONE",
};

const ALL_ORGANISATIONS = "ALL_ORGANISATIONS";

const COLUMNS = {
  ORGANISATION: "ORGANISATION",
  AGREEMENT: "AGREEMENT",
  AGREEMENT_SUBSCRIPTIONS: "AGREEMENT_SUBSCRIPTIONS",
  SETTINGS_E_SIM: "SETTINGS_E_SIM",
};

const SORT_DIRECTIONS = {
  ASC: "ASC",
  DESC: "DESC",
};

export default {
  name: "e-sim-watch",
  mixins: [translateMixin],
  components: {
    eSimWatchDrawer,
  },
  data() {
    return {
      companySettingsStateService: undefined,
      selectedOrganisation: undefined,
      isDrawerOpen: false,
      selectedAgreement: {},
      currentPage: 1,
      currentPageSize: 10,
      allAgreements: [],
      fetchAgreementsError: false,
      loading: true,
      sorting: {
        column: COLUMNS.ORGANISATION,
        columnIndex: 0,
        direction: SORT_DIRECTIONS.DESC,
      },
    };
  },
  async created() {
    await translateSetup(["mybusiness"]);
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
  },
  methods: {
    onOrganisationsChange() {
      if (!this.selectedOrganisation && this.organisations.length) {
        this.selectedOrganisation = ALL_ORGANISATIONS;
        this.fetchAllAgreements();
      }
    },
    onOrganisationSelected(selectedOrganisation) {
      if (this.selectedOrganisation !== selectedOrganisation) {
        analytics.trackEvent(
          analytics.category.COMPANY_SETTINGS,
          analytics.action.FILTER,
          "Organisation"
        );
      }

      this.selectedOrganisation = selectedOrganisation;
    },
    async fetchAgreementsForOrganisation(tscid) {
      const scopeId = this.companySettingsStateService.state.loggedInUser.scopeId;

      try {
        let response;
        response = await corpMdsPolicy.MdsPolicyControllerService.getPolicies(scopeId, tscid);

        let agreements = response.map((agreement) => {
          agreement.tscid = tscid;
          return agreement;
        });

        return agreements;
      } catch (error) {
        // this was added later
        // this makes the code in upper layers that display error message mostly used
        return [];
      }
    },
    async fetchAllAgreements() {
      this.loading = true;
      let calls = [];
      this.organisations.forEach((organisation) => {
        calls.push(this.fetchAgreementsForOrganisation(organisation.value));
      });
      Promise.all(calls)
        .then((agreements) => {
          this.allAgreements = agreements;
          this.loading = false;
        })
        .catch(() => {
          // does never really happen because all errors from fetchAgreementsForOrganisation are swollowed
          this.fetchAgreementsError = true;
          this.loading = false;
          logError("b2b-company-settings", "Failed fetching agreements");
        });
    },
    onHandleChangeButtonClick(event) {
      const selectedAgreementNumber = event.detail.attrs["data-agreement-number"];
      const selectedTscid = event.detail.attrs["data-tscid"];

      this.selectedAgreement = this.getSelectedAgreement(selectedAgreementNumber, selectedTscid);
      this.isDrawerOpen = true;

      analytics.trackEvent(analytics.category.COMPANY_SETTINGS, analytics.action.CLICK, "Change");
    },
    getSelectedAgreement(agreementNumber, tscid) {
      let selectedAgreement = {};
      this.allAgreements.flat(1).forEach((agreement) => {
        if (agreement.agreementNumber === agreementNumber && agreement.tscid == tscid) {
          selectedAgreement = agreement;
        }
      });
      return selectedAgreement;
    },
    onPaginationChange(event) {
      if (event.detail.page > this.currentPage) {
        analytics.trackEvent(
          analytics.category.COMPANY_SETTINGS,
          analytics.action.PAGINATE,
          `Next/${this.currentPage},${this.currentPageSize}-items`
        );
      }

      this.currentPage = event.detail.page;
      this.currentPageSize = event.detail.pageSize;
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    agreementUpdated(agreementNumber, newPolicyType) {
      if (agreementNumber === this.selectedAgreement.agreementNumber) {
        this.selectedAgreement.policyType = newPolicyType;
      }
    },
    getPolicyTypeText(policyType) {
      switch (policyType) {
        case POLICY_TYPES.MANAGER_ONLY:
          return this.t("COMPANY_SETTINGS.E_SIM_WATCH.MANAGER_ONLY");
        case POLICY_TYPES.ALLOW_ALL:
          return this.t("COMPANY_SETTINGS.E_SIM_WATCH.ALLOW_ALL");
        case POLICY_TYPES.PRIVATE_ONLY:
          return this.t("COMPANY_SETTINGS.E_SIM_WATCH.PRIVATE_ONLY");
        case POLICY_TYPES.NONE:
          return "";
      }
      return "";
    },
    getSortingIcon(column) {
      if (this.sorting.column === column) {
        return this.sorting.direction === SORT_DIRECTIONS.ASC ? "arrow-up" : "arrow-down";
      }
      return "sorter";
    },
    getColumnFromText(text) {
      let column;
      switch (text) {
        case this.t("COMPANY_SETTINGS.E_SIM_WATCH.ORGANISATION"):
          column = { name: COLUMNS.ORGANISATION, index: 0 };
          break;
        case this.t("COMPANY_SETTINGS.E_SIM_WATCH.AGREEMENT"):
          column = { name: COLUMNS.AGREEMENT, index: 1 };
          break;
        case this.t("COMPANY_SETTINGS.E_SIM_WATCH.AGREEMENT_SUBSCRIPTIONS"):
          column = { name: COLUMNS.AGREEMENT_SUBSCRIPTIONS, index: 2 };
          break;
        case this.t("COMPANY_SETTINGS.E_SIM_WATCH.SETTINGS_E_SIM"):
          column = { name: COLUMNS.SETTINGS_E_SIM, index: 3 };
          break;
      }
      if (this.selectedOrganisation !== ALL_ORGANISATIONS) {
        column.index -= 1;
      }
      return column;
    },
    onColumnClick(event) {
      const clickedColumn = this.getColumnFromText(event.detail);
      if (clickedColumn.name === this.sorting.column) {
        this.sorting.direction =
          this.sorting.direction === SORT_DIRECTIONS.ASC
            ? SORT_DIRECTIONS.DESC
            : SORT_DIRECTIONS.ASC;
      } else {
        const column = this.getColumnFromText(event.detail);
        this.sorting.column = column.name;
        this.sorting.columnIndex = column.index;
        this.sorting.direction = SORT_DIRECTIONS.DESC;
      }
    },
    sortDescending(rows) {
      let sortedRows = rows.sort((a, b) => {
        if (a[this.sorting.columnIndex] < b[this.sorting.columnIndex]) return -1;
        if (a[this.sorting.columnIndex] > b[this.sorting.columnIndex]) return 1;
        return 0;
      });
      return sortedRows;
    },
    sortAscending(rows) {
      let sortedRows = rows.sort((a, b) => {
        if (a[this.sorting.columnIndex] < b[this.sorting.columnIndex]) return 1;
        if (a[this.sorting.columnIndex] > b[this.sorting.columnIndex]) return -1;
        return 0;
      });
      return sortedRows;
    },
  },
  computed: {
    tableMessage() {
      if (this.fetchAgreementsError) {
        return this.t("COMPANY_SETTINGS.E_SIM_WATCH.FETCH_AGREEMENTS_ERROR");
      } else if (this.noAgreements) {
        return this.t("COMPANY_SETTINGS.E_SIM_WATCH.NO_AGREEMENTS");
      }
      return "";
    },
    noAgreements() {
      return this.rows.length < 1;
    },
    showNonEditableInfo() {
      if (Object.keys(this.mappedAgreements).length > 0) {
        let agreements = this.mappedAgreements[this.selectedOrganisation];
        if (agreements && agreements.length) {
          return agreements.every((a) => !a.editable);
        }
      }

      return false;
    },
    mappedAgreements() {
      let mappedAgreements = {};
      if (this.allAgreements && this.allAgreements.length) {
        mappedAgreements[ALL_ORGANISATIONS] = this.allAgreements.flat(1);
        this.allAgreements.forEach((agreement) => {
          if (agreement.length) {
            let tscid = agreement[0].tscid;
            mappedAgreements[tscid] = agreement;
          }
        });
      }
      return mappedAgreements;
    },
    organisations() {
      let organisations = [];
      if (this.companySettingsStateService) {
        if (!this.companySettingsStateService.state.organisations.error) {
          organisations = this.companySettingsStateService.state.organisations.list.map(
            (organisation) => {
              if (organisation.tscid && organisation.name) {
                return {
                  value: organisation.tscid,
                  label: organisation.name,
                };
              }
            }
          );
        }
      }
      organisations = organisations.filter((element) => {
        return element !== undefined;
      });
      return organisations;
    },
    fetchOrganisationsError() {
      if (this.companySettingsStateService) {
        return this.companySettingsStateService.state.organisations.error;
      }
      return false;
    },
    organisationSelectorOptions() {
      return [
        {
          value: ALL_ORGANISATIONS,
          label: this.t("COMPANY_SETTINGS.E_SIM_WATCH.ALL_ORGANISATIONS"),
        },
        ...this.organisations,
      ];
    },
    columns() {
      return [
        {
          title: `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.ORGANISATION")}`,
          type: "text",
          isChecked: true,
          disabled: false,
          sortable: {
            sortIconName: this.getSortingIcon(COLUMNS.ORGANISATION),
          },
        },
        {
          title: `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.AGREEMENT")}`,
          type: "text",
          isChecked: true,
          disabled: false,
          sortable: {
            sortIconName: this.getSortingIcon(COLUMNS.AGREEMENT),
          },
        },
        {
          title: `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.AGREEMENT_SUBSCRIPTIONS")}`,
          type: "text",
          isChecked: true,
          disabled: false,
          sortable: {
            sortIconName: this.getSortingIcon(COLUMNS.AGREEMENT_SUBSCRIPTIONS),
          },
        },
        {
          title: `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.SETTINGS_E_SIM")}`,
          type: "text",
          isChecked: true,
          disabled: false,
          sortable: {
            sortIconName: this.getSortingIcon(COLUMNS.SETTINGS_E_SIM),
          },
        },
        { title: "", type: "button-text", isChecked: true, disabled: false },
      ];
    },
    rows() {
      let rows = [];
      if (!this.allAgreements) {
        return rows;
      }
      let selectedAgreements = this.mappedAgreements[this.selectedOrganisation];
      if (selectedAgreements && selectedAgreements.length) {
        this.mappedAgreements[this.selectedOrganisation].forEach((agreement, index) => {
          let changeButtonValue = {
            content:
              agreement.policyType !== POLICY_TYPES.NONE
                ? `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.CHANGE")}`
                : `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.ADD")}`,
            attrs: {
              variant: "expressive",
              "data-agreement-number": agreement.agreementNumber,
              "data-tscid": agreement.tscid,
              disabled: !agreement.editable,
              title: !agreement.editable
                ? `${this.t("COMPANY_SETTINGS.E_SIM_WATCH.INFO_CONTENT_TEMP_DISABLED")}`
                : "",
            },
            rowNumber: `${index}`,
          };

          let row = [
            this.tscidToOrganisationName[agreement.tscid],
            `${agreement.agreementName} (${agreement.agreementNumber})`,
            agreement.connectedVoiceSubscriptions,
            this.getPolicyTypeText(agreement.policyType),
            changeButtonValue,
          ];
          rows.push(row);
        });
      }
      return rows;
    },
    sortedRows() {
      let rows = this.rows;
      if (this.sorting.direction === SORT_DIRECTIONS.ASC) {
        return this.sortAscending(rows);
      } else {
        return this.sortDescending(rows);
      }
    },
    displayedRows() {
      let startingRowIndex = (this.currentPage - 1) * this.currentPageSize;
      return this.sortedRows.slice(startingRowIndex, this.currentPageSize * this.currentPage);
    },
    tscidToOrganisationName() {
      let mappedOrganisations = {};
      this.organisations.forEach((organisation) => {
        let tscid = organisation.value;
        mappedOrganisations[tscid] = organisation.label;
      });
      return mappedOrganisations;
    },
  },
  watch: {
    organisations: { handler: "onOrganisationsChange", immediate: true },
  },
};
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables";

.e-sim-watch {
  &__info-wrapper {
    max-width: 67rem;
  }

  &__notification-wrapper {
    margin-top: $telia-spacing-32;
  }

  &__dropdown {
    position: absolute;
    --inline-dropdown-min-width: 25rem;
  }

  &__dropdown-wrapper {
    height: $telia-spacing-32;
    margin-top: $telia-spacing-48;
    margin-bottom: $telia-spacing-12;
  }

  &__notification {
    margin: $telia-spacing-12 0;
  }

  &__dropdown-skeleton {
    width: 20rem;
    height: 2.8rem;
    margin-top: 5.3rem;
    margin-bottom: $telia-spacing-20;
  }
}
</style>
