<template>
  <div class="filter__wrapper">
    <div class="org-selector__wrapper" v-if="showOrganisationSelector">
      <email-recipients-org-selector />
    </div>
    <email-filter v-show="showEmailFilter" />
  </div>
</template>

<script>
import EmailRecipientsOrgSelector from "./email-recipients-org-selector.vue";
import EmailFilter from "./email-filter.vue";
import CompanySettingsStateService from "../../services/company-settings.state.service";
export default {
  name: "organisationEmailFilter",
  inject: ["invoiceEmailStateService"],
  data() {
    return {
      companySettingsStateService: null,
    };
  },
  async created() {
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
  },
  components: {
    EmailFilter,
    EmailRecipientsOrgSelector,
  },
  computed: {
    showOrganisationSelector() {
      if (this.companySettingsStateService) {
        return this.companySettingsStateService.state.organisations.list.length > 1;
      }
      return true;
    },
    showEmailFilter() {
      return this.invoiceEmailStateService.state.data.emailList.length > 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables.scss";
div.filter__wrapper {
  display: flex;
  align-items: center;
  height: 7rem;
  margin: $telia-spacing-24 0;

  div.org-selector__wrapper {
    min-width: 25rem;
    margin-right: $telia-spacing-20;
  }
}
</style>
