import { corpCustomerBillingAccounts } from "@telia/b2b-rest-client";
import { IEmailRecipient } from "./interfaces/IInvoiceEmailState";

export class InvoiceEmailRestService {
  public fetchAvailableEmails(scopeId: string, tscid: string): Promise<IEmailRecipient[]> {
    return new Promise<IEmailRecipient[]>((resolve, reject) => {
      corpCustomerBillingAccounts.BillingAccountsControllerService.getAvailableEmails(
        scopeId,
        tscid
      )
        .then((data: corpCustomerBillingAccounts.AvailableEmailsDTO[]) => {
          const mappedData: IEmailRecipient[] = this._mapAvailableEmails(
            data as corpCustomerBillingAccounts.AvailableEmailsDTO[]
          );
          resolve(mappedData);
        })
        .catch((e) => reject(e));
    });
  }

  public removeEmail(scopeId: string, tscid: string, contactPointId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      corpCustomerBillingAccounts.BillingAccountsControllerService.removeEmail(
        scopeId,
        tscid,
        contactPointId
      )
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  private _mapAvailableEmails(
    response: corpCustomerBillingAccounts.AvailableEmailsDTO[]
  ): IEmailRecipient[] {
    return response.map((availableEmail: corpCustomerBillingAccounts.AvailableEmailsDTO) => ({
      contactPointId: availableEmail.contactPointId || "",
      email: availableEmail.email || "",
      numberOfAccounts: availableEmail.numberOfAccounts || 0,
      used: availableEmail.used || false,
    }));
  }
}
