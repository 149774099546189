<template>
  <section t-id="invoice-email-section">
    <invoice-email-information />
    <organisation-email-filter />

    <b2x-table-skeleton v-if="isEmailsLoading"></b2x-table-skeleton>
    <b2x-table
      v-if="!isEmailsLoading"
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(emails)"
      @buttonTextClicked="removeEmailOrGiveInfo"
      t-id="invoice-email-section-table"
      :message="message"
    >
    </b2x-table>

    <email-recipient-info-modal />
  </section>
</template>

<script>
import { translateMixin } from "../../../src/locale";
import InvoiceEmailStateService from "../../services/invoice-email.state.service";
import CompanySettingsStateService from "../../services/company-settings.state.service";
import EmailRecipientInfoModal from "./email-recipient-info-modal.vue";
import InvoiceEmailInformation from "./invoice-email-information.vue";
import OrganisationEmailFilter from "./organisation-email-filter.vue";

export default {
  name: "invoiceEmail",
  mixins: [translateMixin],
  components: {
    OrganisationEmailFilter,
    EmailRecipientInfoModal,
    InvoiceEmailInformation,
  },
  data() {
    return {
      invoiceEmailStateService: new InvoiceEmailStateService(this.t),
      companySettingsStateService: CompanySettingsStateService.getInstance(),
    };
  },
  provide() {
    return { invoiceEmailStateService: this.invoiceEmailStateService };
  },
  computed: {
    invoiceEmailStateServiceState() {
      return this.invoiceEmailStateService.state;
    },
    isEmailsLoading() {
      return this.invoiceEmailStateServiceState.layout.loading;
    },
    emails() {
      return this.invoiceEmailStateServiceState.data.displayEmailList;
    },
    tableHeaders() {
      return this.invoiceEmailStateService.getHeaders();
    },
    selectedOrganisation() {
      return this.invoiceEmailStateServiceState.data.selectedOrganisation.name;
    },
    message() {
      if (this.invoiceEmailStateServiceState.layout.error.fetchingEmail) {
        return this.t("COMPANY_SETTINGS.INVOICE_EMAIL.ERROR_FETCHING_EMAILS");
      }
      if (this.selectedOrganisation === "") {
        return this.t("COMPANY_SETTINGS.INVOICE_EMAIL.EMPTY_FETCHING_EMAILS_NO_ORG");
      }
      if (this.emails.length === 0) {
        return this.t("COMPANY_SETTINGS.INVOICE_EMAIL.EMPTY_FETCHING_EMAILS");
      }
      return "";
    },
  },
  methods: {
    removeEmailOrGiveInfo(data) {
      this.invoiceEmailStateService.openInfoModalAndSetSelectedEmail(
        data.detail.rowNumber.toString()
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
